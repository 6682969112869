<template>
    <Contactuspage />

    </template>
    
    <script>
    import Contactuspage from './../components/Contactuspage.vue';
  
    export default {
      name: "ContactUs",
      components: {
      Contactuspage
      },
    }
    </script>

    <style>
    
    </style>