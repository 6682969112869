<template>
    <div class="image-container mt-5">
        <div class="content-container" style="color: #011936;">
            <h1 style="font-weight: 700;">Booking Services</h1>
            <div class="breadcrumb_des">
                <p>HOME &nbsp <font-awesome-icon :icon="['fas', 'angle-right']" />&nbsp SERVICES &nbsp
                    <font-awesome-icon :icon="['fas', 'angle-right']" /> &nbsp </p>
                <p class="breadcrumb_name">BOOKING SERVICES</p>
            </div>
        </div>
        <div>
            <img src="../assets/booking_img.png" class="booking-image">
        </div>
    </div>
    <div class="container-fluid p-0" style="background-color:#f4fffd;">
        <div class="container-fluid">
            <div class="support-buttons-container my-4">
                <div class="support-buttons d-flex justify-content-start py-3">
                    <button @click="setActive(0)" class="btn" :class="{ 'active': activeButton === 0 }">Container
                        Booking</button>
                    <button @click="setActive(1)" class="btn " :class="{ 'active': activeButton === 1 }">Booking
                        Solution</button>
                    <button @click="setActive(2)" class="btn " :class="{ 'active': activeButton === 2 }">Customized
                        Solutions</button>
                </div>
                <div class="progress-container mt-2">
                    <div class="progress-bar" :style="progressBarStyle"></div>
                </div>
            </div>
        </div>
        <div class="container ">
            <div v-show="activeButton === 0">
                <div class="row align-items-center">
                    <div class="col-12 col-md-6 mb-4 mb-md-4">
                        <h2 class="call-heading">Container Booking</h2>
                        <p class="call-subtitle">Container booking is a critical aspect of logistics and international
                            trade, involving the reservation and allocation of shipping containers for transporting
                            goods across various distances, typically by sea or rail. This process ensures efficient
                            planning and scheduling of cargo shipments, optimizing the use of container space and
                            transportation routes.<br><br> In container booking, logistics providers or shippers reserve
                            containers in advance to secure space on vessels or trains, ensuring timely and organized
                            movement of goods from origin to destination. This advance booking helps in managing
                            inventory, meeting delivery deadlines, and reducing transportation costs by avoiding
                            last-minute rushes and potential delays.<br><br> Key elements of container booking include
                            selecting the appropriate container size and type based on cargo specifications, determining
                            shipping routes, and coordinating with shipping lines or carriers for availability and
                            scheduling. This process is crucial for businesses involved in global trade, import-export
                            operations, and supply chain management, ensuring smooth and seamless movement of goods
                            while adhering to logistical timelines and regulatory requirements.</p>
                    </div>
                    <div class="col-12 col-md-6 text-center">
                        <div class="image-container position-relative">
                            <img src="../assets/Services/Tab1/Container Booking.png"
                                style="border-radius: 20px; max-width: 100%;" class="img-fluid service-top">
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="activeButton === 1">
                <div class="row align-items-center">
                    <div class="col-12 col-md-6 mb-4 mb-md-4">
                        <h2 class="call-heading">Booking Solution</h2>
                        <p class="call-subtitle"> A booking solution refers to a comprehensive system or platform
                            designed to streamline the process of reserving and managing various services or resources.
                            In the context of logistics and transportation, a booking solution typically facilitates the
                            reservation of transportation services, such as freight forwarding, shipping containers, or
                            passenger travel, among others.<br><br> Booking solutions in logistics integrate digital
                            platforms or software that enable users to efficiently book and manage logistics services
                            online. These solutions often include features like real-time availability checking, price
                            quoting, scheduling, and documentation management, ensuring smooth and organized operations.
                        </p>
                    </div>
                    <div class="col-12 col-md-6 text-center">
                        <div class="image-container position-relative">
                            <img src="@/assets/Services/Tab2/Booking Solution.png"
                                style="border-radius: 20px; max-width: 85%;" class="img-fluid service-top">
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="activeButton === 2">
                <div class="row align-items-center">
                    <div class="col-12 col-md-6 mb-4 mb-md-4">
                        <h2 class="call-heading">Customized Solutions</h2>
                        <p class="call-subtitle"> Customized solutions refer to tailored strategies, services, or
                            products designed to meet specific needs or requirements of individual clients or
                            businesses. In the context of logistics and transportation, customized solutions involve
                            crafting unique approaches to address complex logistical challenges, optimize operations,
                            and enhance efficiency.<br><br> Logistics providers offering customized solutions
                            collaborate closely with clients to understand their unique logistical requirements,
                            challenges, and goals.<br><br> Customized solutions in logistics play a crucial role in
                            enhancing customer satisfaction, operational agility, and competitive advantage. By offering
                            tailored approaches, logistics providers can effectively address unique challenges and
                            deliver value-added services that meet the evolving demands of global supply chains.</p>
                    </div>
                    <div class="col-12 col-md-6 text-center">
                        <div class="image-container position-relative">
                            <img src="@/assets/Services/Tab3/Customized Solutions.png"
                                style="border-radius: 20px; max-width: 72%;" class="img-fluid service-top">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            activeButton: 0,
            buttonWidth: 235, // Default button width for larger screens
            buttonMargin: 10, // Default button margin
        };
    },
    computed: {
        progressBarStyle() {
            const width = this.getButtonWidth();
            const margin = this.getButtonMargin();
            return {
                width: `${width}px`,
                left: `${this.activeButton * (width + margin)}px`,
            };
        },
    },
    methods: {
        setActive(index) {
            this.activeButton = index;
            this.updateProgressBar();
        },
        updateProgressBar() {
            this.$forceUpdate();
        },
        getButtonWidth() {
            // Adjust button width based on screen size
            return window.innerWidth <= 767 ? window.innerWidth * 0.26 : this.buttonWidth;
        },
        getButtonMargin() {
            // Adjust button margin based on screen size
            return window.innerWidth <= 767 ? 5 : this.buttonMargin;
        }
    },
    mounted() {
        window.addEventListener('resize', this.updateProgressBar);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateProgressBar);
    }
};
</script>
<style scoped>
.image-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-family: Montserrat;
}

.breadcrumb_des {
    display: flex;
    flex-direction: row;
}

.breadcrumb_name {
    color: #011936;
    font-weight: 700;
}

.booking-image {
    height: 300px;
    width: 530px;
    object-fit: contain;
}

.support-buttons-container {
    text-align: left;
    margin-top: 0px !important;
    margin-left: 10%;
}

.support-buttons .btn {
    border-radius: 20px;
    font-weight: bold;
    padding: 0.5rem 1.5rem;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    margin: 0 5px;
    width: 235px;
    height: 46px;
    border: 1px solid black;
}

.support-buttons .btn.active {
    background-color: #0b1a34;
    color: #fff;
    border: none;
}

.support-buttons .btn-outline-primary {
    color: #0b1a34;
    border-color: #0b1a34;
}

.support-buttons .btn-outline-primary:hover {
    background-color: #0b1a34;
    color: #fff;
}

.progress-container {
    width: 72%;
    height: 2px;
    margin: 10px 0;
    background-color: #e9ecef;
    position: relative;
}

.progress-bar {
    height: 100%;
    background-color: #ff4a57;
    position: absolute;
    transition: left 0.3s;
}

.call-heading {
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 700;
    line-height: 43.88px;
    text-align: left;
    color: #011936;
}

.call-subtitle {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.38px;
    text-align: left;
    color: #333333;
}

.custom-image {
    border-radius: 20px;
    max-width: 100%;
}

.service-blue {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 80%;
    max-width: 400px;
}

.service-top {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.container {
    width: Fixed (1, 196px)px;
    height: Hug (457px)px;
    top: 636px;
    left: 122px;
    gap: 0px;
    justify-content: space-between;
}

@media (max-width: 767.98px) {
    .support-buttons .btn {
        font-size: 7px;
        width: 100px;
        height: 40px;
        padding: 0.5rem 0.5rem;
    }

    .call-heading {
        font-size: 28px;
        line-height: 35px;
    }

    .call-subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .progress-container {
        width: 100%;
    }

    .service-blue {
        width: 100%;
        max-width: 300px;
    }

    .service-top {
        width: 90%;
        max-width: 270px;
    }

    .image-container {
        left: 0;
        width: 100%;
    }

    .breadcrumb_des {
        display: flex;
        flex-direction: row;
        font-size: 10px;
    }

    .breadcrumb_name {
        color: #011936;
        font-weight: 700;
        font-size: 10px;
    }

    .breadcrumbs h1 {
        font-size: 30px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .support-buttons-container {
        margin-left: 7%;
    }

    .support-buttons .btn {
        font-size: 12px;
        width: 200px;
        height: 46px;
    }

    .call-heading {
        font-size: 32px;
        line-height: 40px;
    }

    .call-subtitle {
        font-size: 18px;
        line-height: 22px;
    }

    .progress-container {
        width: 70%;
    }

    .service-blue {
        width: 90%;
        max-width: 350px;
    }

    .service-top {
        width: 80%;
        max-width: 300px;
    }
}

@media (max-width: 1024px) {
    .content-container h1 {
        font-size: 1.5em;
    }

    .content-container .breadcrumb_des {
        width: 126%;
    }
}

@media (max-width: 992px) {
    .image-container {
        padding-top: 10%;
        flex-direction: column;
        text-align: center;
        height: 250px;
    }

    .booking-image {
        height: 55px;
        width: 250px;
        object-fit: contain;
    }
}
</style>