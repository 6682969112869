<template>
  <div id="app">
    <component :is="currentNavbar" />
    <router-view />
    <font-awesome-icon :icon="['fab', 'whatsapp']" :size="'2x'" class="whatsapp-button" @click="openWhatsApp" />
    <Footer>
    </Footer>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import NavbarHome from '@/components/NavbarHome'
import { mapState } from 'vuex';

export default {
  name: 'App',
  methods: {
    openWhatsApp() {
      const phoneNumber = "+1 (908) 405-5910"; // Replace with your phone number
      const message = "Hello, I'm interested in your services."; // Replace with your predefined message
      const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
      window.open(url, '_blank');
    },
  },
  components: {
    Navbar,
    Footer,
    NavbarHome,
  },
  computed: {
    currentNavbar() {
      return this.$route.path === '/' ? 'NavbarHome' : 'Navbar';
    },
  },
}
</script>

<style scoped>
@keyframes show {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}


.whatsapp-button {
  background-color: #25d366;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 35%;
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: white;
  cursor: pointer;
  z-index: 1;
  animation: pulse 0.8s ease-in-out infinite;
}

.whatsapp-button img {
  width: 50px;
  height: 50px;
}
</style>