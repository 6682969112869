<template>
  <div class="image-container mt-5">
    <div class="content-container" style="color: #011936;">
      <h1 style="font-weight: 700;">Warehousing Services</h1>
      <div class="breadcrumb_des">
        <p>HOME &nbsp <font-awesome-icon :icon="['fas', 'angle-right']" />&nbsp SERVICES &nbsp <font-awesome-icon
            :icon="['fas', 'angle-right']" /> &nbsp </p>
        <p class="breadcrumb_name">WAREHOUSING SERVICES</p>
      </div>
    </div>
    <div>
      <img src="../assets/warehousing_img.png" class="warehousing-image">
    </div>
  </div>
  <div class="container-fluid p-0" style="background-color:#f4fffd;">
    <div class="container-fluid">
      <div class="support-buttons-container my-4">
        <div class="support-buttons d-flex justify-content-start py-3">
          <button @click="setActive(0)" class="btn" :class="{ 'active': activeButton === 0 }">Warehousing</button>
          <button @click="setActive(1)" class="btn " :class="{ 'active': activeButton === 1 }">Warehouse
            Locations</button>
        </div>
        <div class="progress-container mt-2">
          <div class="progress-bar" :style="progressBarStyle"></div>
        </div>
      </div>
    </div>
    <div class="container ">
      <div v-show="activeButton === 0">
        <div class="row align-items-center">
          <div class="col-12 col-md-6 mb-4 mb-md-4">
            <h2 class="call-heading">Warehousing</h2>
            <p class="call-subtitle"> Warehousing plays a crucial role in the logistics and supply chain management
              ecosystem, providing essential storage and distribution facilities for goods before they reach their final
              destinations. These facilities are strategically located to optimize transportation routes and minimize
              delivery times, contributing to efficient inventory management and cost-effective operations.<br><br>
              Modern warehousing facilities are equipped with advanced technologies such as inventory tracking systems,
              automated handling equipment, and climate control solutions to ensure the safety and preservation of
              various types of goods. They serve as hubs for receiving, storing, and dispatching products, supporting
              smooth transitions throughout the supply chain.<br><br> Effective warehousing operations involve
              meticulous planning and organization to maximize storage capacity, minimize handling costs, and facilitate
              rapid order fulfillment. By integrating warehousing solutions into their logistics strategies, businesses
              can enhance responsiveness to customer demands, improve service levels, and maintain competitive
              advantages in dynamic market environments.</p>
          </div>
          <div class="col-12 col-md-6 text-center">
            <div class="image-container position-relative">
              <img src="@/assets/Services/Tab1/Warehousing.png" class="img-fluid custom-image service-top">
            </div>
          </div>
        </div>
      </div>
      <div v-show="activeButton === 1">
        <div class="row align-items-center">
          <div class="col-12 col-md-6 mb-4 mb-md-4">
            <h2 class="call-heading">Warehouse Locations</h2>
            <p class="call-subtitle"> Warehouse locations are strategically chosen points within a logistics network
              where goods are stored, managed, and distributed to meet customer demand efficiently. These facilities are
              strategically positioned near major transportation hubs, ports, or key market areas to optimize supply
              chain operations.<br><br> Choosing the right warehouse locations is crucial for minimizing transportation
              costs, reducing delivery times, and enhancing overall operational efficiency. Factors such as proximity to
              suppliers and customers, transportation infrastructure, labor availability, and local regulations all
              influence the selection of warehouse sites.<br><br> Modern warehouse locations are equipped with advanced
              technologies such as RFID (Radio Frequency Identification), automated storage and retrieval systems
              (AS/RS), and real-time inventory tracking systems. These technologies enable accurate inventory
              management, efficient order fulfillment, and improved visibility across the supply chain.</p>
          </div>
          <div class="col-12 col-md-6 text-center">
            <div class="image-container position-relative">
              <img src="@/assets/Services/Tab2/Warehouse Locations.png" class="img-fluid custom-image service-top">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      activeButton: 0,
      buttonWidth: 240, // Default button width for larger screens
      buttonMargin: 10, // Default button margin
    };
  },
  computed: {
    progressBarStyle() {
      const width = this.getButtonWidth();
      const margin = this.getButtonMargin();
      return {
        width: `${width}px`,
        left: `${this.activeButton * (width + margin)}px`,
      };
    },
  },
  methods: {
    setActive(index) {
      this.activeButton = index;
      this.updateProgressBar();
    },
    updateProgressBar() {
      this.$forceUpdate();
    },
    getButtonWidth() {
      // Adjust button width based on screen size
      return window.innerWidth <= 767 ? window.innerWidth * 0.35 : this.buttonWidth;
    },
    getButtonMargin() {
      // Adjust button margin based on screen size
      return window.innerWidth <= 767 ? 5 : this.buttonMargin;
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateProgressBar);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateProgressBar);
  }
};
</script>
  
<style scoped>
.image-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-family: Montserrat;
}

.breadcrumb_des {
  display: flex;
  flex-direction: row;
}

.breadcrumb_name {
  color: #011936;
  font-weight: 700;
}

.warehousing-image {
  height: 300px;
  width: 530px;
  object-fit: contain;
}

.support-buttons-container {
  text-align: left;
  margin-top: 0px !important;
  margin-left: 10%;
}

.support-buttons .btn {
  border-radius: 20px;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  margin: 0 5px;
  width: 240px;
  height: 46px;
  border: 1px solid black;
}

.support-buttons .btn.active {
  background-color: #0b1a34;
  color: #fff;
  border: none;
}

.support-buttons .btn-outline-primary {
  color: #0b1a34;
  border-color: #0b1a34;
}

.support-buttons .btn-outline-primary:hover {
  background-color: #0b1a34;
  color: #fff;
}

.progress-container {
  width: 48%;
  height: 2px;
  margin: 10px 0;
  background-color: #e9ecef;
  position: relative;
}

.progress-bar {
  height: 100%;
  background-color: #ff4a57;
  position: absolute;
  transition: left 0.3s;
}

.call-heading {
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 700;
  line-height: 43.88px;
  text-align: left;
  color: #011936;
}

.call-subtitle {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  text-align: left;
  color: #333333;
}

.custom-image {
  border-radius: 20px;
  max-width: 100%;
}

.service-blue {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 80%;
  max-width: 400px;
}

.service-top {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

@media (max-width: 767.98px) {
  .support-buttons .btn {
    font-size: 7px;
    width: 100px;
    height: 40px;
    padding: 0.5rem 0.5rem;
  }

  .call-heading {
    font-size: 28px;
    line-height: 35px;
  }

  .call-subtitle {
    font-size: 16px;
    line-height: 20px;
  }

  .progress-container {
    width: 100%;
  }

  .service-blue {
    width: 100%;
    max-width: 300px;
  }

  .service-top {
    width: 90%;
    max-width: 270px;
  }

  .image-container {
    left: 0;
    width: 100%;
  }

  .breadcrumb_des {
    display: flex;
    flex-direction: row;
    font-size: 10px;
  }

  .breadcrumb_name {
    color: #011936;
    font-weight: 700;
    font-size: 10px;
  }

  .breadcrumbs h1 {
    font-size: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .support-buttons-container {
    margin-left: 7%;
  }

  .support-buttons .btn {
    font-size: 12px;
    width: 200px;
    height: 46px;
  }

  .call-heading {
    font-size: 32px;
    line-height: 40px;
  }

  .call-subtitle {
    font-size: 18px;
    line-height: 22px;
  }

  .progress-container {
    width: 70%;
  }

  .service-blue {
    width: 90%;
    max-width: 350px;
  }

  .service-top {
    width: 80%;
    max-width: 300px;
  }
}

@media (max-width: 1024px) {
  .content-container h1 {
    font-size: 1.5em;
  }

  .content-container .breadcrumb_des {
    width: 126%;
  }
}

@media (max-width: 992px) {
  .image-container {
    padding-top: 10%;
    flex-direction: column;
    text-align: center;
    height: 250px;
  }

  .warehousing-image {
    height: 55px;
    width: 250px;
    object-fit: contain;
  }
}
</style>
  