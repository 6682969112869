<template>
    <div class="container-fluid" style="background-color: #f4fffd;">
        <div class="row justify-content-center">
            <h1 class="Heading mb-4 text-center mt-5">News and Announcements</h1>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-11 col-lg-10">
                <span class="badge badge-primary mt-4">{{ post.type }}</span>
                <h3 class="sub-title mt-2">{{ post.title }}</h3>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-11 col-lg-10">
                <img :src="post.image" class="img-fluid w-100 hero-image mb-4 mt-3">
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-12 col-md-11 col-lg-10">
                <p class="text">{{ post.description }}</p>
            </div>
        </div>
        <!-- <div class="row justify-content-center">
            <div class="col-12 col-md-11 col-lg-10">
                <p class="text">Lorem ipsum dolor sit amet consectetur. Mauris pellentesque enim mollis amet magna. Vitae
                    hendrerit ultrices pellentesque commodo condimentum mauris sit. Donec mattis viverra proin vel bibendum
                    fringilla. Tellus arcu in elit vitae id eget interdum egestas. Maecenas dui eget elementum nullam.
                    Elementum arcu purus facilisis dui metus gravida eu dignissim praesent. Sit tempus a nisl duis dictum.
                    Pretium tellus pulvinar dolor risus. Ut amet pretium sit eu volutpat. Sociis risus amet risus cras quam
                    libero venenatis sit. Platea pharetra aliquam in integer sit risus donec magna non. Ultricies quam
                    tellus egestas nec velit aenean. Cursus sem mi ridiculus varius. </p>
                <p class="text">Sed massa varius dui tincidunt amet. Nisl in diam risus netus hendrerit elementum est. Sit
                    velit integer nec pharetra arcu et malesuada neque. Mattis scelerisque leo elementum arcu risus. Neque
                    netus lectus nunc nibh et et.</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-11 col-lg-10">
                <h3 class="h3-title">Ship Location</h3>
                <p class="text">Lorem ipsum dolor sit amet consectetur. Diam id cras egestas auctor nulla arcu commodo
                    rhoncus odio. Posuere pretium turpis faucibus massa aliquet. Aliquam cursus consequat id nullam purus
                    pharetra morbi ornare vitae. Sapien enim eget rutrum vitae vitae diam. Scelerisque imperdiet eu vitae
                    nullam.Sit vehicula ac nunc in. Amet lectus sed morbi netus tempus. Ut faucibus ligula aenean risus
                    nunc. </p>
                <p class="text">Sit aenean sed amet lorem ipsum arcu magna ultricies nulla. Ultrices gravida morbi dictumst
                    tempus cursus condimentum turpis aliquam. Arcu enim elementum lectus ut etiam dolor malesuada ornare
                    purus. Nulla ac vestibulum dictum ut. Quam curabitur aliquam amet porttitor cursus purus. Suspendisse
                    mauris egestas felis egestas.</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-11 col-lg-10">
                <h3 class="h3-title">Destination</h3>
                <p class="text">Lorem ipsum dolor sit amet consectetur. Nunc aliquam in amet nibh. Adipiscing risus interdum
                    dignissim eros. Massa nisi arcu in vitae tellus feugiat massa eget. Quis tellus nibh consectetur
                    curabitur mauris sollicitudin at. Urna suspendisse mauris tristique sed nisl lacus augue. </p>
                <p class="text">Sit tristique sem velit risus ipsum ipsum ultrices varius. Duis sit pretium duis faucibus
                    duis nam. In a ullamcorper ultrices ut nisi erat scelerisque pharetra. Fermentum commodo lobortis sit
                    quam libero tincidunt. Tellus eu aliquet vitae enim aliquet tortor leo odio et. Ultrices vulputate quam
                    etiam convallis duis non tortor dictumst. Posuere feugiat id morbi dictumst convallis non phasellus id
                    pellentesque. Nisi metus volutpat odio bibendum amet. Imperdiet tempus.</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class=" paragraph col-12 col-md-11 col-lg-10">
                <p class="quoted">“ Traveling can expose you to new environments and potential health risks, so it's crucial
                    to take precautions to stay safe and healthy. ”</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-11 col-lg-10">
                <img src="../../assets/blog.webp" class="img-fluid w-100 hero-image mb-4 mt-3">
            </div>
        </div> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['post'])
    },
    created() {
        console.log('Post:', this.post);
    }
};
</script>

<style scoped>
.Heading {
    font-family: Montserrat;
    font-size: 56px;
    font-weight: 700;
    line-height: 68.26px;
    text-align: center;
    color: #011936;
}

.badge {
    padding: 6px 12px;
    gap: 4px;
    border-radius: 6px;
    background-color: #007bff;
    color: #fff;
}

.sub-title {
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 600;
    line-height: 40px;
    text-align: left;
    color: #181A2A;
}

.text {
    font-family: Montserrat;
    font-size: 16px;
    line-height: 24px;
    color: #3B3C4A;

}

.h3-title {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    color: #011936;
}

.quoted {
    font-family: Source Serif Pro;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 32px;
    text-align: left;

}

.paragraph {
    border-left: 4px solid #E8E8EA;
    background: #F6F6F7;
    width: Fill (1, 198px)px;
    height: Hug (128px)px;
    padding: 32px 0px 0px 0px;
    gap: 32px;
    border-radius: 12px 0px 0px 0px;
    border: 0px 0px 0px 4px;
    opacity: 0px;


}

.hero-image {
    border-radius: 8px;
    object-fit: cover;
}

/* Responsive styles */
@media (max-width: 767.98px) {
    .Heading {
        font-size: 36px;
        line-height: 44px;
    }

    .sub-title {
        font-size: 24px;
        line-height: 32px;
    }

    .text {
        font-size: 14px;
        line-height: 20px;
    }

    .h3-title {
        font-size: 20px;
    }

    .quoted {
        font-size: 16px;
        line-height: 22px;
    }
}
</style>
