<template>
  <div class="maincontainer">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="container coverage-header">
            <h2 class="coverage-title">Coverage</h2>
            <h3 class="coverage-subtitle">Where We Operate</h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 col-lg-4 col-sm-12 coverage-list">
          <div class="location">
            <img src="../assets/usa.png" alt="USA" class="img-fluid location-img" />
            <h5>USA</h5>
          </div>
          <div class="location">
            <img src="../assets/uae.png" alt="UAE" class="img-fluid location-img" />
            <h5>UAE</h5>
          </div>
          <div class="location">
            <img src="../assets/china.png" alt="China" class="img-fluid location-img" />
            <h5>China</h5>
          </div>
          <div class="location">
            <img src="../assets/georgia.png" alt="Georgia" class="img-fluid location-img" />
            <h5>Georgia</h5>
          </div>
          <div class="location">
            <img src="../assets/oman.png" alt="Oman" class="img-fluid location-img" />
            <h5>Oman</h5>
          </div>
        </div>
        <div class="col-md-9 col-lg-8 col-sm-12 my-background">
          <!-- Background image with location text below -->
        </div>
      </div>
      <div class="row coverage-locations">
        <div class="col-6 col-md-2 col-lg-2 ">
          <div class="location">
            <img src="../assets/Vector (3).webp" alt="Savannah GA" class="img-fluid location-img" />
            <h5>Savannah GA</h5>
          </div>
        </div>
        <div class="col-6 col-md-2 col-lg-2">
          <div class="location">
            <img src="../assets/Vector (2).webp" alt="Los Angeles CA" class="img-fluid location-img" />
            <h5>Los Angeles CA</h5>
          </div>
        </div>
        <div class="col-6 col-md-2 col-lg-2">
          <div class="location">
            <img src="../assets/Vector (4).webp" alt="Baltimore MD" class="img-fluid location-img" />
            <h5>Baltimore MD</h5>
          </div>
        </div>
        <div class="col-6 col-md-2 col-lg-2">
          <div class="location">
            <img src="../assets/Vector.webp" alt="Houston TX" class="img-fluid location-img" />
            <h5>Houston TX</h5>
          </div>
        </div>
        <div class="col-6 col-md-2 col-lg-2">
          <div class="location">
            <img src="../assets/Vector (6).png" alt="Houston TX" class="img-fluid location-img" />
            <h5>Newark NJ</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Coverage",
};
</script>

<style scoped>
.maincontainer {
  background: #f4fffd;
  padding-block: 50px;
}

.container {
  background: #f4fffd;
}

.coverage-header {
  margin-bottom: 20px;
  text-align: start;
  font-family: Montserrat;
}

.coverage-title {
  font-size: 32px;
  font-weight: 700;
  color: #ff4c60;
}

.coverage-subtitle {
  font-size: 48px;
  font-weight: 700;
}

.coverage-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 5%;
}

.location {
  display: flex;
  align-items: center;
}

.location img {
  margin-right: 10px;
  object-fit: contain;
  align-self: center;
}

.my-background {
  background-image: url('../assets/coverage.png');
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  height: 70vh;
  position: relative;
}

.coverage-locations {
  display: flex;
  flex-direction: row;
  gap: 22px;
  margin-top: 5%;
}

.location h5 {
  font-size: 17px;
  font-weight: 700;
  font-family: Montserrat;
  color: #000000;
  display: flex; align-items: center; justify-content: center;

}

.location-img {
  width: 28px;
  height: 28px;
}

@media screen and (max-width: 1024px) and (max-height: 600px) {
  .coverage-title {
    font-size: 28px;
  }

  .coverage-subtitle {
    font-size: 40px;
  }

  .my-background {
    height: 55vh;
    background-position: left center;
    margin-left: -14%
  }

  .location h5 {
    font-size: 13px;
  }

  .location-img {
    width: 25px;
    height: 25px;
  }

  .coverage-locations {
    gap: 16px;
  }

  .coverage-list {
    margin-top: 5%;
  }
}

@media screen and (max-width: 1024px) and (min-height: 1366px) {
  .coverage-title {
    font-size: 28px;
  }

  .coverage-subtitle {
    font-size: 40px;
  }

  .my-background {
    height: 23vh;
    background-position: left center;
    margin-left: -14%
  }

  .location h5 {
    font-size: 13px;
  }

  .location-img {
    width: 25px;
    height: 25px;
  }

  .coverage-locations {
    gap: 16px;
  }

  .coverage-list {
    margin-top: 5%;
  }
}

@media screen and (max-width: 912px) {
  .coverage-title {
    font-size: 30px;
  }

  .coverage-subtitle {
    font-size: 36px;
  }

  .my-background {
    height: 30vh;
    background-position: left center;
    margin-left: -40px;
  }

  .location h5 {
    font-size: 10px;
  }

  .location-img {
    width: 20px;
    height: 20px;
  }

  .coverage-locations {
    gap: 10px;
  }

  .coverage-list {
    margin-top: 12%;
  }
}

@media screen and (max-width: 768px) {
  .coverage-title {
    font-size: 28px;
  }

  .coverage-subtitle {
    font-size: 32px;
  }

  .my-background {
    height: 40vh;
    background-position: left center;
  }
}

@media screen and (max-width: 576px) and (min-height: 600px) {
  .coverage-title {
    font-size: 24px;
  }

  .coverage-subtitle {
    font-size: 30px;
  }

  .my-background {
    height: 30vh;
    background-position: left center;
    margin-left: 9px
  }

  .coverage-list,
  .coverage-locations {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    flex-direction: row;
    justify-content: center;
  }

  .coverage-list .location,
  .coverage-locations .col-6 {
    flex: 0 0 48%;
    max-width: 48%;
    margin-bottom: 20px;
    justify-content: center;
  }

  .location h5 {
    font-size: 14px;
  }

  .location-img {
    width: 20px;
    height: 20px;
  }
}

@media screen and (min-width: 1250px) {
  .coverage-title {
    font-size: 28px;
  }

  .coverage-subtitle {
    font-size: 40px;
  }

  .my-background {
    height: 47vh;
    background-position: left center;
    margin-left: -18%
  }

  .location h5 {
    font-size: 13px;
  }

  .location-img {
    width: 25px;
    height: 25px;
  }

  .coverage-locations {
    gap: 16px;
  }

  .coverage-list {
    margin-top: 5%;
  }
}
</style>
