<template>
    <div class="gallery">
        <div class="image-container">
            <img src="../assets/Ship2.webp" alt="Gallery Image" class="gallery-image" />
            <div class="overlay"></div>
            <div class="breadcrumbs fade-up">
                <h1 style="font-weight: 700;">About Us</h1>
                <div class="breadcrumb_des">
                    <p>HOME &nbsp <font-awesome-icon :icon="['fas', 'angle-right']" /> &nbsp </p>
                    <p class="breadcrumb_name"> ABOUT US</p>
                </div>
            </div>
        </div>
        <div class="row mx-0 px-0 col-12" style="width: 100%;">
            <div class="About_div_2_1 col-sm-12 mx-0 px-0 col-md-12 col-lg-8">
                <div style="width: 75%;">
                    <h2 class="fade-up" style="color: white; text-align: left;margin-top: 10px; font-weight: 700;">AFG
                        Shipping: Redefining Excellence in Global Logistics</h2>
                    <p class="fade-up" style="color:white; margin-top: 20px;">At AFG Shipping, we are committed to providing
                        timely, efficient, and cost-effective shipping solutions. Our team of seasoned professionals ensures
                        that every interaction is marked by exceptional customer service, a standard we've maintained since
                        our inception. <br><br> We are honored to have earned the trust of our premium clients, who have
                        been with us from the beginning. As we expand our clientele, we remain dedicated to delivering the
                        same high-quality service to our loyal clients, who form the cornerstone of our success in both
                        local and international markets. <br><br> AFG Shipping is more than just a traditional shipping
                        company. We continually expand our service offerings, guided by the belief that our business is
                        driven by customer needs. This customer-centric approach has allowed us to grow our business and
                        explore new market segments successfully. <br><br> Today, we are proud to be industry trendsetters,
                        providing innovative, out-of-the-box solutions that set us apart from the competition. At AFG
                        Shipping, we don’t just meet expectations—we exceed them.</p>
                </div>
            </div>
            <div class="col-sm-12 mx-0 px-0 col-md-12 col-lg-4">
                <img src="../assets/ship3.webp" alt="Shipping Image" style="width: 100%; t:fill;">
            </div>
        </div>
        <div class="py-5 col-sm-12 col-md-12 col-lg-12"
            style="background-color: #F4FFFD; display: flex; justify-content: center; flex-direction: column; align-items: center;">
            <div>
                <h2 id="h2" class="slide-in" style="font-weight: 700; ">Mission & Milestone</h2>
            </div>
            <div style="width: 80%; margin-top: 15px;">
                <p class="fade-up" style="text-align: center; font-size: 18px; color: #011936; font-weight: 400;">As a
                    logistics and shipping company, the AFG mission is to decrease freight management inefficiencies and to
                    provide maximum hard and soft cost savings for our customers in the process of shipping LTL (less than
                    truckload) or truckload freight and small package freight by providing logistics solutions through our
                    powerful and proprietary web-based proprietary transportation management system, the AFG Rater, and
                    integrated managed transportation services. <br><br> As a logistics and shipping company, the AFG
                    mission is to decrease freight management inefficiencies and to provide maximum hard and soft cost
                    savings for our customers in the process of shipping LTL (less than truckload) or truckload freight and
                    small package freight by providing logistics solutions through our powerful and proprietary web-based
                    proprietary transportation management system, the AFG Rater, and integrated managed transportation
                    services. </p>
            </div>
        </div>
        <div style="background-color: #F4FFFD;">
            <div class="container pb-5" style="display: flex; justify-content: center; align-items: center;">
                <div class="row col-md-12 col-sm-12 col-lg-12" style="display: flex; justify-content:center">
                    <div class="col-sm-12 col-md-6 col-lg-4 px-4 my-4 fade-up">
                        <div
                            style="border: 2.5px solid #011936; border-radius: 10px; padding-block: 30px; padding-inline: 20px;">
                            <div
                                style="background-color:#F4FFFD; position: absolute;  top: -25px; display: flex;justify-content: center; align-items: center; height: 60px; width: 60px;">
                                <img src="../assets/1.webp" class="icon-img" alt="Icon">
                            </div>
                            <p style="font-weight: 700;margin-top: 5px; color: #011936;">Innovative</p>
                            <p style="color: #011936; font-size: small; font-weight: 500">Open and creative to customer and employee solutions
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 px-4 my-4 fade-up delay">
                        <div
                            style="border: 2.5px solid #011936; border-radius: 10px; padding-block: 30px; padding-inline: 20px;">
                            <div
                                style="background-color:#F4FFFD; position: absolute;  top: -25px; display: flex;justify-content: center; align-items: center; height: 60px; width: 60px;">
                                <img src="../assets/2.webp" class="icon-img" alt="Icon">
                            </div>
                            <p style="font-weight: 700;margin-top: 5px; color: #011936;">Continuous Improvement</p>
                            <p style="color: #011936; font-size: small; font-weight: 500">Continuous improvement of our people and services
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 px-4 my-4 fade-up delay-1">
                        <div
                            style="border: 2.5px solid #011936; border-radius: 10px; padding-block: 30px; padding-inline: 20px;">
                            <div
                                style="background-color:#F4FFFD; position: absolute;  top: -25px; display: flex;justify-content: center; align-items: center; height: 60px; width: 60px;">
                                <img src="../assets/3.webp" class="icon-img" alt="Icon">
                            </div>
                            <p style="font-weight: 700;margin-top: 5px; color: #011936;">Respect</p>
                            <p style="color: #011936; font-size: small; font-weight: 500">Respect of individuals and their contribution to the
                                team</p>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 px-4 my-4 fade-up delay-2">
                        <div
                            style="border: 2.5px solid #011936; border-radius: 10px; padding-block: 30px; padding-inline: 20px;">
                            <div
                                style="background-color:#F4FFFD; position: absolute;  top: -25px; display: flex;justify-content: center; align-items: center; height: 60px; width: 60px;">
                                <img src="../assets/4.webp" class="icon-img" alt="Icon">
                            </div>
                            <p style="font-weight: 700;margin-top: 5px; color: #011936;">Loyalty</p>
                            <p style="color: #011936; font-size: small; font-weight: 500">Faithful and devoted to our company at all times.
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 px-4 my-4 fade-up delay-3">
                        <div
                            style="border: 2.5px solid #011936; border-radius: 10px; padding-block: 30px; padding-inline: 20px;">
                            <div
                                style="background-color:#F4FFFD; position: absolute;  top: -25px; display: flex;justify-content: center; align-items: center; height: 60px; width: 60px;">
                                <img src="../assets/5.webp" class="icon-img" alt="Icon">
                            </div>
                            <p style="font-weight: 700;margin-top: 5px; color: #011936;">Reliability</p>
                            <p style="color: #011936; font-size: small; font-weight: 500">Perform and maintain our functions in all
                                circumstances</p>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 px-4 my-4 fade-up delay-4">
                        <div
                            style="border: 2.5px solid #011936; border-radius: 10px; padding-block: 30px; padding-inline: 20px;">
                            <div
                                style="background-color:#F4FFFD; position: absolute;  top: -25px; display: flex;justify-content: center; align-items: center; height: 60px; width: 60px;">
                                <img src="../assets/6.webp" class="icon-img" alt="Icon">
                            </div>
                            <p style="font-weight: 700;margin-top: 5px; color: #011936;">Integrity</p>
                            <p style="color: #011936; font-size: small; font-weight: 500">Committed to maintaining the highest ethical
                                standards</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Aboutuspage",
    mounted() {
        const fadeElements = document.querySelectorAll('.fade-up');
        const slideInElements = document.querySelectorAll('.slide-in');

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        };

        const fadeObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target);
                }
            });
        }, observerOptions);

        fadeElements.forEach(element => {
            fadeObserver.observe(element);
        });

        slideInElements.forEach(element => {
            fadeObserver.observe(element);
        });
    }
};
</script>

<style>
.fade-up,
.slide-in {
    font-family: Montserrat;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s, transform 1s;
}

.fade-up.visible,
.slide-in.visible {
    opacity: 1;
    transform: translateY(0);
}

.breadcrumbs {
    opacity: 0;
    animation: fadeIn 1s forwards;
    font-family: Montserrat;
}

.breadcrumbs h1,
.breadcrumbs .breadcrumb_des {
    transform: translateX(-100%);
}

.breadcrumbs h1 {
    animation: slideInLeft 1s forwards;
    animation-delay: 0.6s;
}

.breadcrumbs .breadcrumb_des {
    animation: slideInRight 1s forwards;
    animation-delay: 0.6s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideInLeft {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.gallery {

    width: 100%;
}

.About_div_2 {
    display: flex;
    flex-direction: row;

    background-color: yellowgreen;
}

.About_div_2_1 {
    background-color: #011936;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
}

.breadcrumb_des {
    display: flex;
    flex-direction: row;
}

.icon-img {
    width: 50px;
    height: 50px;

    object-fit: contain;

}

.breadcrumb_name {
    color: #011936;
    font-weight: 700;
}

.image-container {
    position: relative;
    width: 100%;
    height: 400px;
    /* Adjust the height as needed */
    overflow: hidden;
}

.gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.view-more-button {
    display: inline-flex;
    padding-right: 3px;
    align-items: center;
    background-color: #011936;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 5px;
    animation-timing-function: cubic-bezier(0.59, 0, 0, 1.01);
    animation-duration: 600ms;
    width: 130px;
    height: 45px;
    border-radius: 200px;
    display: flex;
    justify-content: space-between;
    font-size: 10px;

}


.view-more-button span {

    background: #ff4c60;
    border-radius: 200px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;


}

.Service_Title {
    margin-top: 5px;
    padding-inline: 5px;
    color: #011936;
}

.Service_des {
    margin-top: 2px;
    padding-inline: 5px;
    color: #011936;

}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    /* Black overlay with 0.6 opacity */
}


.box {
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2),
        0 6px 20px rgba(0, 0, 0, 0.19);
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;


    display: flex;
    margin-top: 30px;
    border-radius: 10px;
    padding: 10px;
    justify-content: space-around;
    flex-direction: column;
}

.box:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2),
        0 12px 40px rgba(0, 0, 0, 0.19);
    transform: scale(1.05);
    /* Increase the size by 5% */

}

.breadcrumbs {
    position: absolute;
    top: 50%;
    left: 10%;
    display: flex;
    flex-direction: column;
    transform: translateY(-50%);
    color: white;
    font-size: 16px;
    z-index: 10;
}

.masonry-gallery {
    padding: 20px;
}

.masonry-item {
    margin-bottom: 30px;
}

.masonry-item img {
    width: 100%;
    height: auto;
    display: block;
}

@media (max-width: 400px) {
    #h2 {
        font-size: 30px;
    }
}
</style>