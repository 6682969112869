<template>
    <Announcement/>
 
    </template>
    
    <script>
    import Announcement from './../components/Announcement.vue';

    export default {
      name: "ContactUs",
      components: {
        Announcement,
    
      },
    }
    </script>

    <style>
    
    </style>