<template>
  <div class="wrapper">
    <div class="content" style="background-color: rgba(0, 0, 0, 0.9); border-radius: 10px;">
      <h4 class="group">THE AMERICAN FREIGHT GROUP</h4>
      <h1 class="big">Big or Small</h1>
      <h1 id="ship">We Ship It All</h1>
      <p style="font-size: 15px;">We provide you with full package services, from bidding on your favorite cars in the
        US auctions and delivering to your desired locations.</p>
      <div class="button-group">
        <button class="button"
          style="background-color:#ED254E; border: none; padding: 8px; border-radius: 5px; cursor: pointer; display: flex; align-items: center; justify-content: center;">
          <div class="d-flex" style="display: flex; align-items: center; justify-content: center;">
            <h6 style="font-weight: 300; color: #fafafa; margin: 0 10px 0 0;">LET’S TALK</h6>
            <font-awesome-icon :icon="['fas', 'arrow-right']" :size="'1x'" style="color: #fafafa" />
          </div>
        </button>
        <div class="search-group">
          <input type="text" v-model="trackingNumber" class="search-input" placeholder="Vin Number / Lot Number">
          <button class="search-button" @click="trackShipment">
            <font-awesome-icon :icon="['fas', 'search']" />
          </button>
        </div>
      </div>
    </div>
    <video v-show="!loading" ref="videoPlayer" height="800" width="100%" loop autoplay muted preload="auto"
      @canplaythrough="handleCanPlayThrough" @error="handleError">
      <source src="../assets/Video/AfgShipping_video.mp4" type="video/mp4" /> Your browser does not support the video
      tag.
    </video>
    <Loader v-if="loading" />
  </div>
  <Offer />
  <Grid />
  <Partners />
  <Coverage />
  <VTracking />
  <Faq />
  <Technology />
  <Aboutus />
  <Contactus />
</template>

<script>
import Aboutus from './../components/Aboutus.vue';
import Coverage from './../components/Coverage.vue';
import VTracking from '../components/VTracking.vue';
import Technology from './../components/Technology.vue';
import Contactus from './../components/Contactus.vue';
import Services from './../components/Services.vue';
import Faq from './../components/Faq.vue';
import Partners from './../components/Partners.vue';
import Grid from './../components/Gridblock.vue';
import Howitworks from './../components/Howitworks.vue';
import Offer from './../components/Offer.vue';
import axios from 'axios';
import Loader from '@/components/Loader.vue';

export default {
  name: "Home",
  components: {
    Offer,
    Grid,
    Howitworks,
    Partners,
    Coverage,
    VTracking,
    Technology,
    Aboutus,
    Faq,
    Services,
    Contactus,
    Loader,
  },
  data() {
    return {
      trackingNumber: '',
      loading: true,
    };
  },
  methods: {
    async trackShipment() {
      if (this.trackingNumber) {
        try {
          const response = await axios.get(`https://trackvinapi.afgshipping.com/api.php?trackingNumber=${this.trackingNumber}`);
          console.log(response.data);
          const data = response.data;
          // Scroll to the top of the viewport
          window.scrollTo(0, 700);
          this.$router.push({
            name: 'Tracking', // Name of the route to navigate to
            query: { vehicleData: JSON.stringify(data) }
          });
        } catch (error) {
          console.error('Error tracking shipment:', error);
        }
      } else {
        alert('Please enter a tracking number.');
      }
    },
    handleCanPlayThrough() {
      this.loading = false;
    },
    handleError() {
      this.loading = false; // Hide loader if there is an error
    },
  }
};
</script>

<style>
/* Animation Starts */
.content {
  opacity: 0;
  /* Start with 0 opacity */
  transform: translateX(-100%);
  /* Start off-screen to the left */
  animation: slideIn 2s forwards ease-out;
  /* Slide in animation */
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-100%);
    /* Start from off-screen left */
  }

  to {
    opacity: 1;
    transform: translateX(0);
    /* End at its original position */
  }
}

/* Animation Ends */

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.wrapper {
  position: relative;
  width: 100%;
  height: 87vh;
  overflow: hidden;
}

#ship {
  font-size: 25px;
} 

.group {
  color: #ED254E;
  font-weight: 500;
  font-size: 20px;
}

.big {
  color: #1974d2;
  font-size: 30px;
  font-weight: 600;
}

.we {
  color: white;
  font-size: 33;
  font-weight: bolder;
}

.content {
  position: absolute;
  top: 30%;
  left: 8%;
  transform: translateY(-50%);
  color: white;
  padding: 20px;
  max-width: 600px;
  font-family: Montserrat;
  z-index: 10;
}

.button-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.search-group {
  display: flex;
  align-items: center;
}

.search-input {
  margin-left: 10px;
  padding: 6px 6px 6px 14px;
  border-radius: 5px;
  border: none;
  font-family: Montserrat;
  font-size: 13px;
  width: 224px;
  outline: none !important;
}

.search-button {
  background-color: #ED254E;
  border: none;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  color: #fafafa;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

video {
  width: 100%;
  height: 87vh;
  object-fit: cover;
}

@media (max-width: 767px) {
  .content {
    left: 5%;
    max-width: 90%;
  }

  .group {
    font-size: 13px;
  }

  .big {
    font-size: 24px;
  }

  h6 {
    font-size: 14px;
  }

  video {
    height: 100vh;
  }

  .wrapper p {
    font-size: 12px;
  }

  #ship {
    font-size: 35px;
  }

  .button-group {
    flex-direction: column;
  }

  .search-input,
  .search-button {
    margin-top: 10px;
    width: 100%;
  }
}
</style>
