<template>
  <footer class="text-light py-5">
    <div class="container">
      <div class="row mb-4">
        <div class="col-md-3">
          <h2 class="heading">
            <img src="../assets/afg_logo.png" alt="AFG Logo" style="width: 100px; height: 50px; object-fit: contain">
          </h2>
          <p class="text mt-4"> AFG was established with the sole aim of enabling growth, by providing efficient and
            prompt logistics, freight forwarding & transportation solutions. </p>
        </div>
        <div class="col-md-3">
          <h3 class="heading1">Get in Touch</h3>
          <div class="contact-item mt-4 d-flex align-items-start">
            <img src="../assets/Address.webp" class="icon" alt="Address Icon">
            <p class="text1 ml-3">AFG Shipping 290 NYE AVE, Irvington, NJ 07111</p>
          </div>
          <div class="contact-item mt-4 d-flex align-items-start">
            <img src="../assets/Email.webp" class="icon" alt="Email Icon">
            <p class="text1 ml-3">Csupport@afgglobalusa.com</p>
          </div>
          <div class="contact-item mt-4 d-flex align-items-start">
            <img src="../assets/call.webp" class="icon" alt="Call Icon">
            <p class="text1 ml-3">+1 862 237 7066</p>
          </div>
        </div>
        <div class="col-md-3">
          <h3 class="heading1">Service</h3>
          <ul class="list mt-4">
            <router-link @click="scrollToTop" to="towingservices" style="color: #CFD3D7; text-decoration: none;">
              <li>Inland transportation</li>
            </router-link>
            <router-link @click="scrollToTop" to="loadingservices" style="color: #CFD3D7; text-decoration: none;">
              <li>Loading Services</li>
            </router-link>
            <router-link @click="scrollToTop" to="shippingservices" style="color: #CFD3D7; text-decoration: none;">
              <li>Shipping Service</li>
            </router-link>
            <router-link @click="scrollToTop" to="warehousingservices" style="color: #CFD3D7; text-decoration: none;">
              <li>Warehousing services</li>
            </router-link>
            <router-link @click="scrollToTop" to="clearinserviceuae" style="color: #CFD3D7; text-decoration: none;">
              <li>Clearance Services in UAE</li>
            </router-link>
            <router-link @click="scrollToTop" to="bookingservices" style="color: #CFD3D7; text-decoration: none;">
              <li>Booking Services</li>
            </router-link>
            <router-link @click="scrollToTop" to="halfcutservices" style="color: #CFD3D7; text-decoration: none;">
              <li>Half-cut Services</li>
            </router-link>
            <router-link @click="scrollToTop" to="customersupport" style="color: #CFD3D7; text-decoration: none;">
              <li>24/7 customer support</li>
            </router-link>
          </ul>
        </div>
        <div class="col-md-3">
          <h3 class="heading1">Join a Newsletter</h3>
          <label class="text2 mt-4">Your Email</label>
          <input v-model="email" type="email" class="form-control my-2" placeholder="Enter Your Email">
          <button class="btn mt-3" @click="subscribe">Subscribe</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <p class="copyright-text">© {{ new Date().getFullYear() }} AFG Shipping. All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    scrollToTop() {
      // Scroll to the top of the viewport
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style scoped>
footer {
  background: #011936;
}

.heading,
.heading1 {
  font-family: Montserrat;
  font-weight: 700;
}

.heading {
  font-size: 32px;
}

.heading1 {
  font-size: 16px;
}

.text,
.text1,
.text2,
.list {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  color: #CFD3D7;
}

.list {
  list-style: disc;
  padding-left: 20px;
}

.form-control {
  width: 80%;
  font-size: 14px;
  padding: 5px 10px;
  font-family: Montserrat;
}

.btn {
  background-color: #ff4c60;
  color: white;
  border-radius: 8px;
  font-family: Montserrat;
}

.btn:hover {
  color: white;
  background-color: #eb3347;
}

.contact-item {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.icon {
  width: 24px;
  height: 24px;
}

.copyright-text {
  font-family: Montserrat;
  font-size: 14px;
  color: #CFD3D7;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .heading {
    font-size: 24px;
  }

  .heading1 {
    font-size: 14px;
  }

  .text,
  .text1,
  .text2 {
    font-size: 14px;
  }

  .form-control {
    width: 100%;
  }

  .btn {
    width: 100%;
    height: auto;
  }

  .contact-item {
    flex-direction: row;
    align-items: flex-start;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-bottom: 5px;
  }
}
</style>
