<template>
    <Trackingpage />

    </template>
    
    <script>
    import Trackingpage from './../components/Trackingpage.vue';
  
    export default {
      name: "ContactUs",
      components: {
        Trackingpage
      },
    }
    </script>

    <style>
    
    </style>