<template>
  <div style="background-color: #f4fffd; padding-bottom: 7%;">
    <div class="container">
      <div class="row faq-container">
        <div class="col-sm-12 col-md-12 col-lg-6 faq-content">
          <h2>FAQs</h2>
          <h3>How Can We Help You?</h3>
          <div class="scroll-box" ref="scrollBox">
            <ul>
              <li v-for="(faq, index) in faqs" :key="index" @click="toggleAnswer(index)"
                :class="{ active: activeIndex === index }">
                <h4>
                  <span :class="{ active: activeIndex === index }">{{ index + 1 }}.</span> {{ faq.question }}
                </h4>
                <p v-if="activeIndex === index">{{ faq.answer }}</p>
                <div class="line" :class="{ active: activeIndex === index }"></div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-0 col-md-12 col-lg-6 faq-image-container" v-show="showImages">
          <div class="bg_image">
            <div class="contact-box">
              <p style="font-weight: 500;">Still have<br> questions?</p>
              <router-link to="/contact-us" class="button" @click="scrollToTop">Contact Us</router-link>
              <p>OR</p>
              <p style="font-size: 9.48px; margin-bottom: 0px;">write us:</p>
              <p style="color: #ED254E; font-size: 9.48px; margin-top: 0px;">csupport@afgglobalusa.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FaqSection',
  data() {
    return {
      activeIndex: 0,
      faqs: [
        {
          question: 'What is the cheapest way to ship a car?',
          answer: 'Ocean freight Container transport is roughly 50% cheaper than the RoRo.\
          About 90% of all vehicle shipments go by Container shipping from United States, which is actually very safe.'
        },
        {
          question: 'Can I track the progress of my shipment online?',
          answer: 'Yes, online tracking is available to you 24 hours a day and is updated regularly.\
          Upon scheduling your shipment, you will receive an email from us that will allow you to track the status of your shipment online.'
        },
        {
          question: 'How do I pay for my auto shipping?',
          answer: 'We accept all major credit cards, electronic transfer, postal money order, and bank/certified check for the deposit or full pre-payment of your shipment.\
          If a balance is due upon delivery, it can be paid directly in bank/certified check, or postal money order.'
        },
        {
          question: 'Can I transport a non-operational vehicle?',
          answer: 'Yes, you can transport non-operational vehicles...'
        },
        {
          question: 'How long does it take to transport a vehicle?',
          answer: 'The transportation time can vary based on...'
        },
        {
          question: 'Can I transport a non-operational vehicle?',
          answer: 'Yes, you can transport non-operational vehicles...'
        }
      ],
      showImages: true // Initially show images, hide on smaller screens
    };
  },
  mounted() {

  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },

  }
};
</script>
<style scoped>
.faq-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.track-container {
  display: flex;
  margin-bottom: 20px;
}

.track-container input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  flex: 1;
}

.track-container button {
  padding: 10px 20px;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
}

.faq-content {
  padding-right: 20px;
  font-family: Montserrat;
}

.faq-content h2 {
  color: #ff5a5f;
  font-size: 24px;
  margin-bottom: 10px;
}

.faq-content h3 {
  color: #2c3e50;
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: 700;
}

.bg_image {
  width: 80%;
  height: 100vh;
  max-width: 85%;
  background-image: url('../assets/faqs.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  border-radius: 150px 150px 150px 150px;
}

.scroll-box {
  height: calc(100vh - 130px);
  /* Adjust this value based on the height of the images and other elements */
  margin-top: 30px;
  overflow-y: auto;
  padding-right: 10px;
}

.scroll-box ul {
  list-style: none;
  padding: 0;
}

.scroll-box li {
  margin-bottom: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  position: relative;
}

.scroll-box h4 {
  font-size: 16px;
  color: #011936;
  padding-top: 10px;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 18.19px;
  text-align: left;
  display: flex;
  align-items: center;
}

.scroll-box h4 span {
  border: 1px solid black;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.scroll-box h4 span.active {
  background-color: #011936;
  color: white;
  border: 1px solid black;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-box p {
  color: #011936;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 16.54px;
  text-align: left;
  margin-top: 10px;
  margin-left: 50px;
}

.line {
  height: 1.36px;
  background-color: grey;
  margin-top: 20px;
  width: 70%;
  margin-left: 10px;
}

.line.active {
  background-color: #ED254E;
}

.scroll-box::-webkit-scrollbar {
  width: 5px;
}

.scroll-box::-webkit-scrollbar-track {
  background: #f4fffd;
}

.scroll-box::-webkit-scrollbar-thumb {
  background: #011936;
  border-radius: 4.5px;
}

.faq-image-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.contact-box {
  background-color: #011936;
  border: 10px solid white;
  color: white;
  padding: 20px;
  position: absolute;
  bottom: -120px;
  height: 340px;
  left: -20px;
  text-align: center;
  border-radius: 100px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
}

.contact-box .button {
  background-color: white;
  border: none;
  padding: 10px 10px;
  color: #011936;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 700;
}

.contact-box p {
  margin: 10px 0;
}

@media (max-width: 768px) {
  .faq-container {
    flex-direction: row;
    padding-bottom: 0px;
  }

  .faq-content {
    padding-right: 0;
    margin-bottom: 20px;
  }

  .faq-image-container {
    align-items: flex-end;
    display: none;
    /* Hide images on small screens */
  }

  .faq-content h2 {
    font-size: 20px;
  }

  .faq-content h3 {
    font-size: 28px;
  }

  .scroll-box {
    height: auto;
    max-height: 70vh;
  }

  .scroll-box h4 {
    font-size: 10px;
    padding-top: 5px;
  }

  .scroll-box p {
    font-size: 10px;
    margin-top: 5px;
    margin-left: 42px;
  }

  .scroll-box h4 span.active {
    width: 35px;
    height: 35px;
  }

  .scroll-box h4 span {
    width: 35px;
    height: 35px;
  }

  .bg_image {
    width: 90%;
    height: 70vh;
    margin-top: 18%;
  }

  .line {
    height: 1.36px;
    background-color: grey;
    margin-top: 10px;
    width: 70%;
    margin-left: 10px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .faq-container {
    flex-direction: row;
  }

  .faq-content {
    padding-right: 15px;
  }

  .faq-image-container {
    align-items: center;
    display: none;
    /* Hide images on medium screens (iPad) */
  }

  .bg_image {
    width: 83%;
    height: 40vh;
  }

  .scroll-box {
    height: calc(73vh - 250px);
  }

  .scroll-box h4 {
    font-size: 13px;
  }

  .scroll-box p {
    font-size: 12px;
  }
}

@media (min-width: 1024px)and (min-height: 1366px) {
  .faq-container {
    flex-direction: row;
  }

  .faq-content {
    padding-right: 20px;
  }

  .faq-image-container {
    align-items: center;
  }

  .bg_image {
    display: block;
    width: 80%;
    height: 45vh;
  }

  .contact-box {
    bottom: 0px;
  }

  .scroll-box {
    height: calc(53vh - 250px);
  }
}

@media (min-width: 1024px)and (max-height: 600px) {
  .faq-container {
    flex-direction: row;
  }

  .faq-content {
    padding-right: 20px;
  }

  .faq-image-container {
    align-items: center;
  }

  .bg_image {
    display: block;
    width: 80%;
    height: 100vh;
  }

  .contact-box {
    bottom: 0px;
  }

  .scroll-box {
    height: calc(120vh - 250px);
  }
}

@media (max-width: 820px) {
  .faq-image-container {
    align-items: center;
    display: none;
    /* Hide images on medium screens (iPad) */
  }
}

@media (min-width: 1280px) {
  .bg_image {
    display: block;
    width: 80%;
    height: 80vh;
  }

  .contact-box {
    bottom: 0px;
  }

  .scroll-box {
    height: calc(90vh - 250px);
  }
}

@media (min-width: 1326px) {
  .bg_image {
    display: block;
    width: 80%;
    height: 100vh;
  }

  .contact-box {
    bottom: 0px;
  }

  .scroll-box {
    height: calc(115vh - 250px);
  }
}
</style>
