<template>
    <div class="image-container mt-5">
        <div class="content-container" style="color: #011936;">
            <h1 style="font-weight: 700;">Tracking</h1>
            <div class="breadcrumb_des">
                <p>HOME &nbsp <font-awesome-icon :icon="['fas', 'angle-right']" /> &nbsp </p>
                <p class="breadcrumb_name"> TRACKING</p>
            </div>
        </div>
        <div>
            <img src="../assets/Tracking3.png" alt="Gallery Image" class="tracking-image" />
        </div>
        <!-- <div class="breadcrumbs">
            <h1 style="font-weight: 700; color: #011936;">Tracking</h1>
            <div class="breadcrumb_des">
                <p style="color: #011936;">HOME &nbsp <font-awesome-icon :icon="['fas', 'angle-right']" /> &nbsp </p>
                <p class="breadcrumb_name" style="color: #011936;">TRACKING</p>
            </div>
        </div> -->
    </div>
    <div class="tracking-section py-5">
        <div class="container">
            <div class="text-center mb-4">
                <h1 class="tracking-subtitle">Track Your Vehicle</h1>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="tracking-form">
                        <p class="tracking-instructions text-center"> Enter your Vin No or Lot No to track your shipments.
                        </p>
                        <div class="input-group mb-3">
                            <input v-model="trackingNumber" type="text" class="form-control"
                                placeholder="Vin Number / Lot Number" />
                        </div>
                        <div class="text-center">
                            <button class="btn" type="button" @click="trackShipment">Track</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-3" v-if="vehicleData.status == 'success'">
            <!-- Vehicle Images Section -->
            <div class="row rounded" style="background-color: #FFFFFF;"
                v-if="vehicleData.data.images && vehicleData.data.images.length > 0">
                <div class="col-md-12">
                    <h2 class="mt-3 mb-3 text-left">Vehicle Images</h2>
                </div>
                <div class="col-md-3 col-sm-12" v-for="(image, index) in vehicleData.data.images" :key="image.id">
                    <img :src="`https://admin.afgshipping.com/uploads/${image.thumbnail}`" alt="Vehicle Image"
                        class="img-fluid rounded" @click="openLightbox(index)" title="Click image to zoom it"
                        style="cursor: pointer;">
                </div>
            </div>
            <div class="row rounded" style="background-color: #FFFFFF;" v-else>
                <div class="col-md-12">
                    <h2 class="mt-3 mb-3 text-left">Vehicle Images</h2>
                    <div class="alert alert-danger" role="alert"> No images found! </div>
                </div>
            </div>
            <vue-easy-lightbox :visible="lightboxVisible" :imgs="lightboxImages" :index="lightboxIndex"
                @hide="lightboxVisible = false" />
        </div>
        <div class="container mt-5">
            <div v-if="vehicleData && vehicleData.status == 'success'">
                <!-- Vehicle Detail Section -->
                <div class="row mt-4 mb-4 rounded" style="background-color:#FFFFFF;">
                    <div class="col-12">
                        <h2 class="text-left" style="padding-top: 1%;"> Vehicle Detail <span class="float-right vin-number">
                                VIN No: <span style="color: #95989D">{{ vehicleData && vehicleData.data ?
                                    vehicleData.data.vin : '' }}</span> </span>
                        </h2>
                    </div>
                    <div class="line mt-2"></div>
                    <!-- <div class="col-11 col-md-6 col-lg-3 mb-3" style="padding-top: 2%">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Customer</h5>
                                <p class="card-text">Werner Steuber <br> P. NO: 754-938-4255</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6 col-lg-3 mb-3" style="padding-top: 2%">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Address</h5>
                                <p class="card-text">644 Hazel Canyon Apt. 398,<br> Sawaynberg</p>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-12 col-md-6 col-lg-6 mb-3" style="padding-top: 2%">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Vehicle Information</h5>
                                <p class="card-text" id="vehicle-info">
                                    <br><b>Year - Make - Model:</b> {{ vehicleData && vehicleData.data ?
                                        vehicleData.data.year : '' }} - {{ vehicleData && vehicleData.data ?
        vehicleData.data.make : '' }} - {{ vehicleData && vehicleData.data ?
        vehicleData.data.model : '' }} <br><b>Vehicle Model:</b> {{ vehicleData &&
        vehicleData.data ? vehicleData.data.model : '' }} <br><b>Keys:</b> {{ vehicleData &&
        vehicleData.data && vehicleData.data.keys === "1" ? 'Yes' : 'No' }} <br><b>Shipping
                                        via:</b> AFG Shipping
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 mb-3" style="padding-top: 2%">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Order Date</h5>
                                <p class="card-text" v-html="formatDateTime(vehicleData.data.created_at)"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Tracking Section -->
                <div class="row rounded mt-4" style="background-color:#FFFFFF;">
                    <div class="col-12">
                        <h3 class="text-center" style="padding-top: 2%; font-weight: bold;"> Container Number: <span
                                class="num">
                                <template v-if="vehicleData.data.streamship_line === 'MSC'">
                                    <a href="https://www.msc.com/en/track-a-shipment" target="_blank"
                                        rel="noopener noreferrer"> {{ vehicleData.data.container_number }} </a>
                                </template>
                                <template v-if="vehicleData.data.streamship_line === 'MAERSK'">
                                    <a href="https://www.maersk.com/tracking/" target="_blank"
                                        rel="noopener noreferrer"> {{ vehicleData.data.container_number }} </a>
                                </template>
                                <template v-else> {{ vehicleData.data.container_number ? vehicleData.data.container_number :
                                    '-' }} </template>
                            </span>
                        </h3>
                        <div class="line mt-4"></div>
                        <div class="status-section d-flex justify-content-between align-items-center mt-4">
                            <span class="status-item"><strong>Status:</strong> {{ statusTitle }}</span>
                            <span class="status-item date"><strong>Updated Date:</strong> {{ new
                                Date(vehicleData.data.updated_at).toLocaleDateString('en-US', {
                                    year: 'numeric', month:
                                        'long', day: '2-digit'
                                }) }}</span>
                        </div>
                        <div class="tracking-container mt-5">
                            <div v-for="(step, index) in steps" :key="index"
                                :class="['tracking-step', isActive(step) ? 'active' : '']">
                                <div class="icon-container">
                                    <img :src="step.icon" :alt="step.name" />
                                </div>
                                <span>{{ step.name }}</span>
                            </div>
                            <div class="progress-container">
                                <div class="progress-bar" :style="progressBarStyle"></div>
                            </div>
                            <!-- <div class="tracking-line" :style="trackingLineStyle"></div>
                            <div class="tracking-line-bg"></div> -->
                            <!-- <div class="tracking-step active">
                                <div class="icon-container">
                                    <img src="../assets/Confirmorder.webp" alt="Confirmed Order" />
                                </div>
                                <span>Confirmed Order</span>
                            </div>
                            <div class="tracking-step active">
                                <div class="icon-container">
                                    <img src="../assets/processingorder.webp" alt="Processing Order" />
                                </div>
                                <span>Processing Order</span>
                            </div>
                            <div class="tracking-step active">
                                <div class="icon-container">
                                    <img src="../assets/productdispatch.webp" alt="Product Dispatched" />
                                </div>
                                <span>Product Dispatched</span>
                            </div>
                            <div class="tracking-step">
                                <div class="icon-container">
                                    <img src="../assets/ondelivery.webp" alt="On Delivery" />
                                </div>
                                <span>On Delivery</span>
                            </div>
                            <div class="tracking-step">
                                <div class="icon-container">
                                    <img src="../assets/productdeliver.webp" alt="Product Delivered" />
                                </div>
                                <span>Product Delivered</span>
                            </div>
                            <div class="tracking-line"></div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="vehicleData && vehicleData.status == 'error'">
                <div class="row">
                    <div class="col-md-12">
                        <div class="alert alert-danger" role="alert"> {{ vehicleData.message }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
    components: {
        VueEasyLightbox
    },
    data() {
        return {
            lightboxVisible: false,
            lightboxIndex: 0,
            trackingNumber: '',
            vehicleData: this.$route.query.vehicleData ? JSON.parse(this.$route.query.vehicleData) : {},
            stepStatus: [
                { value: 3, name: 'Dispatched', icon: require('../assets/productdispatch.webp') },
                { value: 1, name: 'On Hand', icon: require('../assets/Confirmorder.webp') },
                { value: 2, name: 'Manifest', icon: require('../assets/processingorder.webp') },
                { value: 4, name: 'Shipped', icon: require('../assets/Shipped.png') },
                { value: 5, name: 'Arrived', icon: require('../assets/Arrived.png') }
            ]
        };
    },
    methods: {
        async trackShipment() {
            if (this.trackingNumber) {
                try {
                    const response = await axios.get(`https://trackvinapi.afgshipping.com/api.php?trackingNumber=${this.trackingNumber}`);
                    console.log(response.data);
                    this.vehicleData = response.data;
                    window.scrollTo(0, 700);
                } catch (error) {
                    console.error('Error tracking shipment:', error);
                }
            } else {
                alert('Please enter a tracking number.');
            }
        },
        formatDateTime(datetime) {
            if (!datetime) return '';

            const options = { weekday: 'short', day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
            const date = new Date(datetime);

            return `${date.toLocaleDateString('en-US', options)}`;
        },
        isActive(step) {
            const statusOrder = ['1', '2', '3', '4', '5'];
            const currentStatus = this.vehicleData.data?.status;

            if (!currentStatus) {
                return false;
            }

            if (currentStatus === '3') {
                return step.value.toString() === '3';
            }

            const currentIndex = statusOrder.indexOf(currentStatus);
            const stepIndex = statusOrder.indexOf(step.value.toString());

            return stepIndex <= currentIndex;
        },
        openLightbox(index) {
            this.lightboxIndex = index;
            this.lightboxVisible = true;
        }
    },
    computed: {
        statusTitle() {
            const statusTitles = {
                1: 'On Hand',
                2: 'Manifest',
                3: 'Dispatch',
                4: 'Shipped',
                5: 'Arrived'
            };
            return statusTitles[this.vehicleData.data?.status] || '-';
        },
        steps() {
            return this.stepStatus;
        },
        trackingLineStyle() {
            const totalSteps = this.steps.length;
            const activeSteps = this.steps.filter(step => this.isActive(step)).length;
            const activePercentage = (activeSteps / totalSteps) * 100;

            return {
                left: '8%',
                right: activeSteps === totalSteps ? '8%' : `${100 - activePercentage}%`,
                position: 'absolute',
                top: '40%',
                height: '4px',
                zIndex: 0,
                background: `linear-gradient(to right, #011936 ${activePercentage}%, #d3d3d3 ${activePercentage}%)`
            };
        },
        progressBarStyle() {
            const totalSteps = this.steps.length;  // Get the total number of steps
            const activeSteps = this.steps.filter(step => this.isActive(step)).length;  // Count active steps

            const activePercentage = (activeSteps / totalSteps) * 100;  // Calculate the active width as a percentage

            return {
                width: `${activePercentage}%`,
                height: '100%',
                backgroundColor: '#011936'
            };
        },
        lightboxImages() {
            return this.vehicleData.data.images.map(image => `https://admin.afgshipping.com/uploads/${image.thumbnail}`);
        }
    }
};
</script>


<style scoped>
#vehicle-info {
    margin-top: -15px;
}

.card-body {
    flex: 0px;
}

.col-md-3,
.col-sm-12 {
    padding-right: 0px !important;
}

.progress-container {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #d3d3d3;
    margin-top: 4%;
}

.gallery {
    width: 100%;
}

.breadcrumb_des {
    display: flex;
    flex-direction: row;
}

.breadcrumb_name {
    color: #011936;
    font-weight: 700;
}

.image-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-family: Montserrat;
}

.tracking-image {
    justify-content: center;
    height: 400px;
    width: 450px;
    object-fit: contain;
}

.tracking {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    color: #011936;

}

.tracking-section {
    background: #f4fffd;
    padding: 50px 0;
}

.tracking-subtitle {
    font-size: 32px;
    font-weight: 700;
    color: #002d72;
}

.tracking-form .input-group {
    display: flex;
    align-items: center;
    font-family: Montserrat;
}

.tracking-form .input-group .form-control {
    border-radius: 0.25rem;
    margin-right: 10px;
}

.tracking-instructions {
    margin-top: 20px;
    font-size: 14px;
    color: #6c757d;
}

.tracking-result {
    margin-top: 20px;
    font-size: 16px;
    color: #333;
}

.btn {
    background-color: #ff4c60;
    padding: 5px 10px;
    border-radius: 8px;
    color: white;
}

.btn:hover {
    color: white;
    background-color: #eb3347;
}

@media (max-width: 767px) {
    .tracking-form .input-group {
        flex-direction: column;
        align-items: center;
    }

    .tracking-form .input-group .form-control {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .card-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card-container .col-11 {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    .card-container .card {
        width: 100%;
        max-width: 300px;
    }

}

@media (max-width: 1024px) {
    .content-container h1 {
        font-size: 1.5em;
    }

    .content-container .breadcrumb_des {
        width: 126%;
    }
}

@media (max-width: 992px) {
    .image-container {
        padding-top: 20%;
        flex-direction: column;
        text-align: center;
    }

    .tracking-image {
        height: 250px;
        width: 250px;
        object-fit: contain;
    }
}


.image-container {
    text-align: center;
}

h2 {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 29.26px;
    text-align: left;
    color: #333333;

}

.row {
    background: #f4fffd;

}

.line {
    border: 0.1px solid #23232133;
    width: 96%;
    height: 0px;
    gap: 2px;
    border: 0.1px 0px 0px 0px;
    margin-left: 2%;

}

.img-fluid {
    width: 70%;
    height: 70%;
}

.card {
    width: 100%;
    height: 147.65px;
    gap: 0px;
    border-radius: 12.76px;
    justify-content: center;
}

.text-left {
    font-family: Montserrat;
    font-size: 19.97px;
    font-weight: 600;
    line-height: 24.34px;
    color: #333333;
}

.vin-number {
    font-family: Montserrat;
    font-size: 19.97px;
    font-weight: 500;
    line-height: 24.34px;
    text-align: right;
    color: #333333;
}

.card-title {
    font-family: Montserrat;
}

.card-text {
    font-family: Montserrat;
    font-size: 12.84px;
    font-weight: 400;
    line-height: 15.65px;
    text-align: left;
    color: #333333;
}

.text-center {
    font-family: Montserrat;
    font-size: 19.97px;
    font-weight: 600;
    line-height: 24.34px;
    color: #333333;
}

.num {
    font-family: Montserrat;
    font-size: 19.97px;
    font-weight: 600;
    line-height: 24.34px;
    color: #95989D;


}

.status-section {
    background-color: #f8f9fa;
    padding: 10px 15px;
    border: 1px solid #e0e0e0;
    border-radius: 0.25rem;
}

.status-item {
    font-size: 1rem;
    color: #95989D;
    font-family: Montserrat;
}

.status-item strong {
    color: #333333;
    font-family: Montserrat;
    font-size: 17px;
    font-weight: 600;
    line-height: 18.29px;
    text-align: left;
}

.shipping {
    text-align: left;
}

.status {
    text-align: center;
    flex: 1;
}

.date {
    text-align: left;
}

@media (max-width: 767.98px) {
    .status-section {
        flex-direction: column;
        align-items: flex-start;
    }

    .status-item {
        width: 100%;
        margin-bottom: 5px;
    }

    .status {
        text-align: left;
    }

    .text-left {
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 600;
        line-height: 24.34px;
        color: #333333;
    }

    .vin-number {
        font-family: Montserrat;
        font-size: 17px;
        font-weight: 500;
        line-height: 24.34px;
        text-align: right;
        color: #333333;
    }

}

.tracking-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 20px 0;
    font-family: Montserrat;
}

.tracking-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 1;
}

.tracking-step .icon-container {
    width: 90px;
    height: 90px;
    background-color: #d3d3d3;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.tracking-step.active .icon-container {
    background-color: #002d72;
}

.tracking-step img {
    width: 40px;
    height: 40px;
}

.tracking-line-bg {
    position: absolute;
    top: 40%;
    left: 8%;
    right: 8%;
    height: 4px;
    background-color: #d3d3d3;
    z-index: 0;
}

.tracking-step.active~.tracking-line {
    background-color: #002d72;
}

.text-center {
    font-weight: 500;
    color: #343a40;
}


@media (max-width: 767.98px) {
    .tracking-container {
        flex-direction: column;
        align-items: center;
    }

    .tracking-step {
        margin-bottom: 20px;
    }

    .tracking-line {
        display: none;
    }

    .progress-container {
        display: none;
    }

    .col-sm-12 {
        text-align: center;
    }

    .vin-number {
        font-size: 12px;
    }

    .text-left {
        font-size: 13px;
    }

    .card-text {
        font-size: 11px;
    }
}

@media (min-width: 1024px) {
    .col-11 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .card {
        width: 100%;
    }
}
</style>