<template>

  <Aboutuspage />

</template>

<script>

import Aboutuspage from './../components/Aboutuspage.vue';

export default {
  name: "About",
  components: {
    Aboutuspage
  },
}
</script>

<style>
</style>