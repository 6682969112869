<template>
  <div class="carousel-container">
    <div class="carousel-wrapper" ref="carouselWrapper">
      <div class="carousel" :style="{ transform: `translateX(-${currentIndex * cardWidth}px)` }">
        <div v-for="(card, index) in cards" :key="index" class="carousel-card">
          <div class="card-content" :class="{ 'special-card': card.title === 'Clearance Service' }">
            <img :src="card.image" :alt="card.title" class="gallery-image_carousel" />
            <div class="innerbox">
              <h6>{{ card.title }}</h6>
              <p>{{ card.des }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-buttons">
      <button class="nav-button left" @click="scrollLeft">&#8249;</button>
      <button class="nav-button right" @click="scrollRight">&#8250;</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cards: [
        { title: "Customer Support", des: 'We provide 24/7 customer support to our valued clients via email, calls, and website.', image: require('../assets/Services/24-hours-support.png') },
        { title: "Half-Cut Service", des: 'Half-cut service provides cost-effective vehicle parts by cutting cars in half.', image: require('../assets/Services/halfcut.png') },
        { title: "Booking Service", des: 'Booking service streamlines hassle-free reservations for transportation, ensuring convenience and efficiency.', image: require('../assets/Services/booking.png') },
        { title: "Towing Service", des: 'Towing service provides reliable vehicle transport when they cannot move independently.', image: require('../assets/Services/towing.png') },
        { title: "Shipping Service", des: 'Shipping service securely transports goods over long distances with reliability and efficiency.', image: require('../assets/Services/shipping.png') },
        { title: "Warehouse Service", des: 'Warehouse service offers secure and organized storage solutions for various types of goods.', image: require('../assets/Services/warehouse.png') },
        { title: "Clearance Service", des: 'Clearance Service ensures efficient customs processing  for international shipments.', image: require('../assets/Services/clearance.png') },
        { title: "Loading Service", des: 'Loading service ensures goods are securely and efficiently loaded onto transportation vehicles.', image: require('../assets/Services/loading.png') },
      ],
      currentIndex: 0,
      cardWidth: 0,
      cardsPerRow: this.getCardsPerRow() // Adjust as needed
    };
  },
  mounted() {
    this.updateCardWidth(); // Initial calculation
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    scrollLeft() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    scrollRight() {
      if (this.currentIndex < this.cards.length - this.cardsPerRow) {
        this.currentIndex++;
      }
    },
    updateCardWidth() {
      if (this.$refs.carouselWrapper) {
        const containerWidth = this.$refs.carouselWrapper.clientWidth;
        this.cardWidth = containerWidth / this.cardsPerRow;
      }
    },
    handleResize() {
      this.cardsPerRow = this.getCardsPerRow();
      this.updateCardWidth();
    },
    getCardsPerRow() {
      if (window.innerWidth <= 768) {
        return 1;
      } else {
        return 3;
      }
    }
  }
};
</script>
<style scoped>
.carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1200px;
  margin: auto;
}

.carousel-wrapper {
  overflow: hidden;
}

.innerbox {
  background-color: white;
  border-radius: 10px;
  padding: 5%;
}

.innerbox h6 {
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  font-size: 15px;
}

.innerbox p {
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.carousel {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.carousel-card {
  box-sizing: border-box;
  padding: 10px;
  flex: 0 0 100%;
}

.card-content {
  background-color: #F1F1F1;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gallery-image_carousel {
  height: 100px;
  width: 200px;
  object-fit: contain;
  margin-block: 5px;
  border-radius: 10px;
}

.nav-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.nav-button {
  background: #f04e45;
  border: none;
  color: white;
  padding-inline: 10px;
  font-size: 1.5rem;
  margin: 0 5px;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .carousel-card {
    flex: 0 0 calc(100% / 3);
  }
}
</style>