<template>
  <div style="background: #f4fffd;">
  <div class="container" >
    <div class="app-header">
      <h2 class="app-title">About</h2>
      <h1 class="app-subtitle">About US</h1>
    </div>
    <div class="row align-items-center info-section">
      <div class="col-md-12 col-lg-6 info-text">
        <p class="about_des"> AFG Shipping is a professionally managed shipping company running with an objective to offer
          timely, efficient and cost-effective shipping services. Thanks to our well experienced work force, we have
          portrayed exceptional customer service consistently. It's a privilege to have the premium clients who trusted us
          since the inception. While we focus on increasing our clientele, we never compromise on delivering high quality
          service to our existing clients, who built the foundation for us in the local and international market. </p>
        <button class="btn btn-primary view-more-button" @click="viewMore"> View More <span><font-awesome-icon
              :icon="['fas', 'arrow-right']" :size="'1x'" /> </span>
        </button>
      </div>
      <div class="col-md-6 info-images">
        <img src="../assets/About-Us3.png" alt="cars" class="image-large img-fluid" />
      </div>

      
    </div>
  </div>
</div>
</template>





<script>
export default {
  name: "Aboutus",
  data() {
    return {
      images: [],
    };
  },
  methods: {
    viewMore() {
      // Your view more logic here
    },
  },
};
</script>

<style scoped>
.info-section {
  color: #333;
  border-radius: 15px;
  margin-left: 4%;
}

.app-header {
  margin-left: 5%;
}

.app-title {
  font-size: 1.7em;
  color: #ff4c60;
  font-family: Montserrat;
  font-weight: 600;
}

.about_des {
  color: #333333;
}

.app-subtitle {
  font-size: 2em;
  color: #002d72;
  font-family: Montserrat;
  font-weight: 700;
}

.info-text {
  padding-right: 20px;
}

.info-text p {
  font-family: Montserrat;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
  max-height: 216px;
  overflow: hidden;
}

.view-more-button {
  align-items: center;
  background-color: #011936;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 40px;
  animation-timing-function: cubic-bezier(0.59, 0, 0, 1.01);
  animation-duration: 600ms;
  width: 160px;
  height: 45px;
  border-radius: 200px;
  display: flex;
  justify-content: space-between;
  font-family: Montserrat;
  font-size: 12px;
  padding-left: 4%;
}

.view-more-button span {

  background: #ff4c60;
  border-radius: 200px;
  height: 90%;
  width: 23%;
}

.info-images {
  position: relative;
  height: 500px;
  /* background: url('../assets/cubicshape.webp') no-repeat center center;
  background-size: contain; */
}

@media (max-width: 768px) {
  .info-images {
    position: relative;
    height: 505px;
    display: none;
    background: url('../assets/cubicshape.webp') no-repeat center center;
    background-size: contain;
  }

  .about_des {
    color: #333333;
    margin-top: 25px;
  }
}


.info-images img {
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  position: absolute;
}

.image-large {
  width: 100%;
  max-width: 500px;
  height: auto;
  left: 60%;
  transform: translateX(-50%);

}

.image-medium {
  width: 100%;
  max-width: 200px;
  height: auto;
  top: 50px;
  left: 25%;
  transform: translateX(-50%);
  z-index: 2;
}

.image-small {
  width: 100%;
  max-width: 200px;
  height: auto;
  top: 270px;
  left: 66%;
  transform: translateX(-50%);
  z-index: 2;
}
</style>
