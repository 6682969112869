<template>
    <div class="container-fluid mt-5" style="background-color: #f4fffd;">
        <div class="row justify-content-center pt-5">
            <h1 class="Heading text-center">News and Announcements</h1>
        </div>
        <div class="row">
            <div class="col-12 mb-4">
                <swiper v-if="!loading" :scrollbar="{ hide: false, draggable: true }" :modules="modules" :initial-slide="1"
                    class="mySwiper mb-5">
                    <swiper-slide v-for="(card, index) in featuredPosts" :key="index" class="swiper-slide-custom"
                        style="background-color: #f4fffd;">
                        <div class="card mb-5 swiper-card" style="background-color: #f4fffd;">
                            <img :src="card.image" class="swiper-slide-img" :alt="card.title">
                            <div class="overlay">
                                <h5 class="card-title">{{ card.title }}</h5>
                                <p class="card-text">{{ card.description }}</p>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <div v-else>
                    <!-- Skeleton loader for Swiper -->
                    <div class="swiper-skeleton">
                        <div class="skeleton-card" v-for="n in 5" :key="n"></div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <h4 class="h4-title">Latest Post</h4>
        </div>
        <div class="container justify-content-start pb-md-4">
            <div class="row">
                <div v-if="loading" class="col-12 col-md-6 col-lg-4" v-for="n in 6" :key="n">
                    <!-- Skeleton loader for posts -->
                    <div class="card2-skeleton mb-4 mt-3 mx-auto"></div>
                </div>
                <div v-else class="col-12 col-md-6 col-lg-4" v-for="(post, index) in posts" :key="index">
                    <div class="card2 mb-4 mt-3 mx-auto">
                        <img :src="post.image" class="card-img-top" :alt="post.title">
                        <div class="card-body">
                            <h5 class="card-title2">{{ post.type }}</h5>
                            <!-- <a :href="'/blog/' + generateSlug(post.title)" class="card-text2">{{ post.title }}</a> -->
                            <router-link :to="{ name: 'BlogDetails', params: { slug: generateSlug(post.title) } }"
                                class="card-text2" @click.native="setPost(post)" @click="scrollToTop"> {{ post.title }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <button v-if="!loading && shouldShowLoadMore" class="button" @click="loadMore">Load More</button>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Scrollbar } from 'swiper/modules';
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },

    setup() {
        return {
            modules: [Scrollbar],
        };
    },
    data() {
        return {
            featuredPosts: [],
            posts: [],
            currentPage: 1,
            perPage: 6,
            loading: true,
        };
    },
    methods: {
        fetchPosts() {
            axios.get(`http://trackvinapi.afgshipping.com/blog_api.php?page=${this.currentPage}&limit=${this.perPage}`)
                .then(response => {
                    if (response.data.status === 'success') {
                        const allPosts = response.data.data;
                        this.posts = allPosts;
                        this.featuredPosts = allPosts.filter(post => post.featured);
                        this.loading = false;
                    } else {
                        console.error('Error fetching posts:', response.data.message);
                        this.loading = false;
                    }
                })
                .catch(error => {
                    console.error('Error fetching posts:', error);
                    this.loading = false;
                });
        },
        loadMore() {
            this.currentPage++;
            axios.get(`http://trackvinapi.afgshipping.com/blog_api.php?page=${this.currentPage}&limit=${this.perPage}`)
                .then(response => {
                    if (response.data.status === 'success') {
                        const allPosts = response.data.data;
                        this.posts = [...this.posts, ...allPosts.filter(post => !post.featured)];
                        this.featuredPosts = [...this.featuredPosts, ...allPosts.filter(post => post.featured)];
                        this.loading = false;
                    } else {
                        console.error('Error fetching posts:', response.data.message);
                        this.loading = false;
                    }
                })
                .catch(error => {
                    console.error('Error fetching posts:', error);
                    this.loading = false;
                });
        },
        generateSlug(title) {
            // Function to generate a URL-friendly slug from the title
            return title.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
        },
        ...mapActions(['updatePost']),
        setPost(post) {
            this.updatePost(post);
        },
        scrollToTop() {
            // Scroll to the top of the viewport
            window.scrollTo(0, 0);
        }
    },
    mounted() {
        this.fetchPosts();
    },
    computed: {
        shouldShowLoadMore() {
            return this.posts.length > 9;
        }
    }
};
</script>

<style scoped>
.swiper-skeleton {
  display: flex;
  gap: 1rem;
}

.skeleton-card,
.card2-skeleton {
  height: 200px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}





.text {
    margin: 0;
    padding: 0;
    text-align: right;
}

.home,
.arrow,
.services {
    display: inline-block;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.38px;
    text-align: left;
    margin-right: 17px;
}

.home,
.arrow {
    color: #333333;
}

.services {
    color: #011936;
}

.Heading {
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 700;
    line-height: 68.26px;
    text-align: center;
    color: #011936;
}

.swiper {
    width: 100%;
    height: 100%;
    padding: 0 6%;
}


.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-card {
    position: relative;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    border-radius: 50px;
}

.swiper-slide-img {
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.overlay {
    position: absolute;
    bottom: 30px;
    left: 50px;
    color: #fff;
    font-size: 12px;
    background-color: transparent;
}

.card-title {
    font-family: Montserrat;
    font-size: 38px;
    font-weight: 700;
    text-align: left;
    line-height: 18px;
    color: #FFFFFF;
    margin-top: 34%;
}

.card-text {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: #FFFFFF;
    padding-top: 0.5%;

}

.h4-title {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 27.56px;
    text-align: start;
    color: #011936;
    margin-left: 5%;
}

.card-title2 {
    font-family: Work Sans;
    font-size: 13.76px;
    font-weight: 500;
    text-align: left;
    color: #4B6BFB;
}

.card-text2 {
    font-family: Work Sans;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #181A2A;
    text-decoration: none;
}

.card2 {
    width: 100%;
    max-width: 385px;
    height: auto;
    padding: 15px;
    border-radius: 12px;
    border: 1px solid #E8E8EA;
    background-color: white;
}

.button {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 12px 20px;
    border-radius: 6px;
    background: #ED254E;
    border: #ED254E;
    color: #FFFFFF;
}

@media (max-width: 1100px) {
    .text {
        text-align: center;
        margin-right: 0;
    }

    .Heading {
        font-size: 20px;
        line-height: 44px;
    }

    .swiper {
        padding: 0 4%;
    }

    .swiper-scrollbar {
        height: 3px;
        top: auto;
        bottom: 10px;
        transform: translateY(0);
    }

    .swiper-card {
        width: 100%;
        border-radius: 50px;
    }

    .swiper-slide-img {
        width: 100%;
        height: auto;
    }

    .card2 {
        max-width: 100%;
        padding: 10px;
        border-radius: 10px;
    }

    .button {
        font-size: 14px;
        padding: 10px 15px;
    }
}

@media (max-width: 767.98px) {
    .card-title {
        font-size: 18px;

    }

    .card-text {
        font-size: 8px;
    }

    .overlay {
        bottom: 10px;
        left: 5px;
        font-size: 10px;
        padding: 3px;
    }

    .h4-title {
        font-size: 20px;
    }

    .card-title2 {
        font-size: 12px;
    }

    .card-text2 {
        font-size: 18px;
    }

    .card2 {
        padding: 8px;
        border-radius: 8px;
    }
}
</style>